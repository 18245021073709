import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_TOKEN;

export const getAPIData: any = (url: string) => {
    return axios.get(baseUrl + "/" + url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postAPI = (
    url: string,
    data: {
        name: string;
        companyName: string;
        email: string;
        message: string;
        phoneNumber: string;
    }
) => {
    return axios.post(
        baseUrl + "/" + url,
        {
            data,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": `application/json`,
            },
        }
    );
};

export function splitStringIntoNumber(input: string): number {
    const number = parseInt((input.match(/\d+/g) || []).join(""), 10) || 0;
    return number;
}
export function splitStringIntoNonNumber(input: string): string {
    const nonNumber = (input.match(/[^\d]+/g) || []).join("");
    return nonNumber;
}
