import React from "react";
import NavBar from "../components/CommonComponents/Navbar";
import BrandCaseStudy from "../components/caseStudies/BrandCaseStudy/index";
import Footer from "../components/CommonComponents/Footer";

const BrandCaseStudies = () => {
    return (
        <>
            <NavBar />
            <BrandCaseStudy />
            <Footer />
        </>
    );
};

export default BrandCaseStudies;
