export const countryCode = [
    {
        name: "Afghanistan",
        code: "AF",
        phoneCode: "+93",
        image: "/assets/countryFlags/af.png",
    },
    {
        name: "Albania",
        code: "AL",
        phoneCode: "+355",
        image: "/assets/countryFlags/al.png",
    },
    {
        name: "Algeria",
        code: "DZ",
        phoneCode: "+213",
        image: "/assets/countryFlags/dz.png",
    },
    {
        name: "Andorra",
        code: "AD",
        phoneCode: "+376",
        image: "/assets/countryFlags/ad.png",
    },
    {
        name: "Angola",
        code: "AO",
        phoneCode: "+244",
        image: "/assets/countryFlags/ao.png",
    },
    {
        name: "Antigua and Barbuda",
        code: "AG",
        phoneCode: "+1268",
        image: "/assets/countryFlags/ag.png",
    },
    {
        name: "Argentina",
        code: "AR",
        phoneCode: "+54",
        image: "/assets/countryFlags/ar.png",
    },
    {
        name: "Armenia",
        code: "AM",
        phoneCode: "+374",
        image: "/assets/countryFlags/am.png",
    },
    {
        name: "Australia",
        code: "AU",
        phoneCode: "+61",
        image: "/assets/countryFlags/au.png",
    },
    {
        name: "Austria",
        code: "AT",
        phoneCode: "+43",
        image: "/assets/countryFlags/at.png",
    },
    {
        name: "Azerbaijan",
        code: "AZ",
        phoneCode: "+994",
        image: "/assets/countryFlags/az.png",
    },
    {
        name: "Bahamas",
        code: "BS",
        phoneCode: "+1242",
        image: "/assets/countryFlags/bs.png",
    },
    {
        name: "Bahrain",
        code: "BH",
        phoneCode: "+973",
        image: "/assets/countryFlags/bh.png",
    },
    {
        name: "Bangladesh",
        code: "BD",
        phoneCode: "+880",
        image: "/assets/countryFlags/bd.png",
    },
    {
        name: "Barbados",
        code: "BB",
        phoneCode: "+1246",
        image: "/assets/countryFlags/bb.png",
    },
    {
        name: "Belarus",
        code: "BY",
        phoneCode: "+375",
        image: "/assets/countryFlags/by.png",
    },
    {
        name: "Belgium",
        code: "BE",
        phoneCode: "+32",
        image: "/assets/countryFlags/be.png",
    },
    {
        name: "Belize",
        code: "BZ",
        phoneCode: "+501",
        image: "/assets/countryFlags/bz.png",
    },
    {
        name: "Benin",
        code: "BJ",
        phoneCode: "+229",
        image: "/assets/countryFlags/bj.png",
    },
    {
        name: "Bhutan",
        code: "BT",
        phoneCode: "+975",
        image: "/assets/countryFlags/bt.png",
    },
    {
        name: "Bolivia",
        code: "BO",
        phoneCode: "+591",
        image: "/assets/countryFlags/bo.png",
    },
    {
        name: "Bosnia and Herzegovina",
        code: "BA",
        phoneCode: "+387",
        image: "/assets/countryFlags/ba.png",
    },
    {
        name: "Botswana",
        code: "BW",
        phoneCode: "+267",
        image: "/assets/countryFlags/bw.png",
    },
    {
        name: "Brazil",
        code: "BR",
        phoneCode: "+55",
        image: "/assets/countryFlags/br.png",
    },
    {
        name: "Brunei",
        code: "BN",
        phoneCode: "+673",
        image: "/assets/countryFlags/bn.png",
    },
    {
        name: "Bulgaria",
        code: "BG",
        phoneCode: "+359",
        image: "/assets/countryFlags/bg.png",
    },
    {
        name: "Burkina Faso",
        code: "BF",
        phoneCode: "+226",
        image: "/assets/countryFlags/bf.png",
    },
    {
        name: "Burundi",
        code: "BI",
        phoneCode: "+257",
        image: "/assets/countryFlags/bi.png",
    },
    {
        name: "Cabo Verde",
        code: "CV",
        phoneCode: "+238",
        image: "/assets/countryFlags/cv.png",
    },
    {
        name: "Cambodia",
        code: "KH",
        phoneCode: "+855",
        image: "/assets/countryFlags/kh.png",
    },
    {
        name: "Cameroon",
        code: "CM",
        phoneCode: "+237",
        image: "/assets/countryFlags/cm.png",
    },
    {
        name: "Canada",
        code: "CA",
        phoneCode: "+1",
        image: "/assets/countryFlags/ca.png",
    },
    {
        name: "Central African Republic",
        code: "CF",
        phoneCode: "+236",
        image: "/assets/countryFlags/cf.png",
    },
    {
        name: "Chad",
        code: "TD",
        phoneCode: "+235",
        image: "/assets/countryFlags/td.png",
    },
    {
        name: "Chile",
        code: "CL",
        phoneCode: "+56",
        image: "/assets/countryFlags/cl.png",
    },
    {
        name: "China",
        code: "CN",
        phoneCode: "+86",
        image: "/assets/countryFlags/cn.png",
    },
    {
        name: "Colombia",
        code: "CO",
        phoneCode: "+57",
        image: "/assets/countryFlags/co.png",
    },
    {
        name: "Comoros",
        code: "KM",
        phoneCode: "+269",
        image: "/assets/countryFlags/km.png",
    },
    {
        name: "Congo, Democratic Republic of the",
        code: "CD",
        phoneCode: "+243",
        image: "/assets/countryFlags/cd.png",
    },
    {
        name: "Congo, Republic of the",
        code: "CG",
        phoneCode: "+242",
        image: "/assets/countryFlags/cg.png",
    },
    {
        name: "Costa Rica",
        code: "CR",
        phoneCode: "+506",
        image: "/assets/countryFlags/cr.png",
    },
    {
        name: "Croatia",
        code: "HR",
        phoneCode: "+385",
        image: "/assets/countryFlags/hr.png",
    },
    {
        name: "Cuba",
        code: "CU",
        phoneCode: "+53",
        image: "/assets/countryFlags/cu.png",
    },
    {
        name: "Cyprus",
        code: "CY",
        phoneCode: "+357",
        image: "/assets/countryFlags/cy.png",
    },
    {
        name: "Czech Republic",
        code: "CZ",
        phoneCode: "+420",
        image: "/assets/countryFlags/cz.png",
    },
    {
        name: "Denmark",
        code: "DK",
        phoneCode: "+45",
        image: "/assets/countryFlags/dk.png",
    },
    {
        name: "Djibouti",
        code: "DJ",
        phoneCode: "+253",
        image: "/assets/countryFlags/dj.png",
    },
    {
        name: "Dominica",
        code: "DM",
        phoneCode: "+1767",
        image: "/assets/countryFlags/dm.png",
    },
    {
        name: "Dominican Republic",
        code: "DO",
        phoneCode: "+1809",
        image: "/assets/countryFlags/do.png",
    },
    {
        name: "Ecuador",
        code: "EC",
        phoneCode: "+593",
        image: "/assets/countryFlags/ec.png",
    },
    {
        name: "Egypt",
        code: "EG",
        phoneCode: "+20",
        image: "/assets/countryFlags/eg.png",
    },
    {
        name: "El Salvador",
        code: "SV",
        phoneCode: "+503",
        image: "/assets/countryFlags/sv.png",
    },
    {
        name: "Equatorial Guinea",
        code: "GQ",
        phoneCode: "+240",
        image: "/assets/countryFlags/gq.png",
    },
    {
        name: "Eritrea",
        code: "ER",
        phoneCode: "+291",
        image: "/assets/countryFlags/er.png",
    },
    {
        name: "Estonia",
        code: "EE",
        phoneCode: "+372",
        image: "/assets/countryFlags/ee.png",
    },
    {
        name: "Eswatini",
        code: "SZ",
        phoneCode: "+268",
        image: "/assets/countryFlags/sz.png",
    },
    {
        name: "Ethiopia",
        code: "ET",
        phoneCode: "+251",
        image: "/assets/countryFlags/et.png",
    },
    {
        name: "Fiji",
        code: "FJ",
        phoneCode: "+679",
        image: "/assets/countryFlags/fj.png",
    },
    {
        name: "Finland",
        code: "FI",
        phoneCode: "+358",
        image: "/assets/countryFlags/fi.png",
    },
    {
        name: "France",
        code: "FR",
        phoneCode: "+33",
        image: "/assets/countryFlags/fr.png",
    },
    {
        name: "Gabon",
        code: "GA",
        phoneCode: "+241",
        image: "/assets/countryFlags/ga.png",
    },
    {
        name: "Gambia",
        code: "GM",
        phoneCode: "+220",
        image: "/assets/countryFlags/gm.png",
    },
    {
        name: "Georgia",
        code: "GE",
        phoneCode: "+995",
        image: "/assets/countryFlags/ge.png",
    },
    {
        name: "Germany",
        code: "DE",
        phoneCode: "+49",
        image: "/assets/countryFlags/de.png",
    },
    {
        name: "Ghana",
        code: "GH",
        phoneCode: "+233",
        image: "/assets/countryFlags/gh.png",
    },
    {
        name: "Greece",
        code: "GR",
        phoneCode: "+30",
        image: "/assets/countryFlags/gr.png",
    },
    {
        name: "Grenada",
        code: "GD",
        phoneCode: "+1473",
        image: "/assets/countryFlags/gd.png",
    },
    {
        name: "Guatemala",
        code: "GT",
        phoneCode: "+502",
        image: "/assets/countryFlags/gt.png",
    },
    {
        name: "Guinea",
        code: "GN",
        phoneCode: "+224",
        image: "/assets/countryFlags/gn.png",
    },
    {
        name: "Guinea-Bissau",
        code: "GW",
        phoneCode: "+245",
        image: "/assets/countryFlags/gw.png",
    },
    {
        name: "Guyana",
        code: "GY",
        phoneCode: "+592",
        image: "/assets/countryFlags/gy.png",
    },
    {
        name: "Haiti",
        code: "HT",
        phoneCode: "+509",
        image: "/assets/countryFlags/ht.png",
    },
    {
        name: "Honduras",
        code: "HN",
        phoneCode: "+504",
        image: "/assets/countryFlags/hn.png",
    },
    {
        name: "Hungary",
        code: "HU",
        phoneCode: "+36",
        image: "/assets/countryFlags/hu.png",
    },
    {
        name: "Iceland",
        code: "IS",
        phoneCode: "+354",
        image: "/assets/countryFlags/is.png",
    },
    {
        name: "India",
        code: "IN",
        phoneCode: "+91",
        image: "/assets/countryFlags/in.png",
    },
    {
        name: "Indonesia",
        code: "ID",
        phoneCode: "+62",
        image: "/assets/countryFlags/id.png",
    },
    {
        name: "Iran",
        code: "IR",
        phoneCode: "+98",
        image: "/assets/countryFlags/ir.png",
    },
    {
        name: "Iraq",
        code: "IQ",
        phoneCode: "+964",
        image: "/assets/countryFlags/iq.png",
    },
    {
        name: "Ireland",
        code: "IE",
        phoneCode: "+353",
        image: "/assets/countryFlags/ie.png",
    },
    {
        name: "Israel",
        code: "IL",
        phoneCode: "+972",
        image: "/assets/countryFlags/il.png",
    },
    {
        name: "Italy",
        code: "IT",
        phoneCode: "+39",
        image: "/assets/countryFlags/it.png",
    },
    {
        name: "Jamaica",
        code: "JM",
        phoneCode: "+1876",
        image: "/assets/countryFlags/jm.png",
    },
    {
        name: "Japan",
        code: "JP",
        phoneCode: "+81",
        image: "/assets/countryFlags/jp.png",
    },
    {
        name: "Jordan",
        code: "JO",
        phoneCode: "+962",
        image: "/assets/countryFlags/jo.png",
    },
    {
        name: "Kazakhstan",
        code: "KZ",
        phoneCode: "+7",
        image: "/assets/countryFlags/kz.png",
    },
    {
        name: "Kenya",
        code: "KE",
        phoneCode: "+254",
        image: "/assets/countryFlags/ke.png",
    },
    {
        name: "Kiribati",
        code: "KI",
        phoneCode: "+686",
        image: "/assets/countryFlags/ki.png",
    },
    {
        name: "Korea, North",
        code: "KP",
        phoneCode: "+850",
        image: "/assets/countryFlags/kp.png",
    },
    {
        name: "Korea, South",
        code: "KR",
        phoneCode: "+82",
        image: "/assets/countryFlags/kr.png",
    },
    {
        name: "Kosovo",
        code: "XK",
        phoneCode: "+383",
        image: "/assets/countryFlags/xk.png",
    },
    {
        name: "Kuwait",
        code: "KW",
        phoneCode: "+965",
        image: "/assets/countryFlags/kw.png",
    },
    {
        name: "Kyrgyzstan",
        code: "KG",
        phoneCode: "+996",
        image: "/assets/countryFlags/kg.png",
    },
    {
        name: "Laos",
        code: "LA",
        phoneCode: "+856",
        image: "/assets/countryFlags/la.png",
    },
    {
        name: "Latvia",
        code: "LV",
        phoneCode: "+371",
        image: "/assets/countryFlags/lv.png",
    },
    {
        name: "Lebanon",
        code: "LB",
        phoneCode: "+961",
        image: "/assets/countryFlags/lb.png",
    },
    {
        name: "Lesotho",
        code: "LS",
        phoneCode: "+266",
        image: "/assets/countryFlags/ls.png",
    },
    {
        name: "Liberia",
        code: "LR",
        phoneCode: "+231",
        image: "/assets/countryFlags/lr.png",
    },
    {
        name: "Libya",
        code: "LY",
        phoneCode: "+218",
        image: "/assets/countryFlags/ly.png",
    },
    {
        name: "Liechtenstein",
        code: "LI",
        phoneCode: "+423",
        image: "/assets/countryFlags/li.png",
    },
    {
        name: "Lithuania",
        code: "LT",
        phoneCode: "+370",
        image: "/assets/countryFlags/lt.png",
    },
    {
        name: "Luxembourg",
        code: "LU",
        phoneCode: "+352",
        image: "/assets/countryFlags/lu.png",
    },
    {
        name: "Madagascar",
        code: "MG",
        phoneCode: "+261",
        image: "/assets/countryFlags/mg.png",
    },
    {
        name: "Malawi",
        code: "MW",
        phoneCode: "+265",
        image: "/assets/countryFlags/mw.png",
    },
    {
        name: "Malaysia",
        code: "MY",
        phoneCode: "+60",
        image: "/assets/countryFlags/my.png",
    },
    {
        name: "Maldives",
        code: "MV",
        phoneCode: "+960",
        image: "/assets/countryFlags/mv.png",
    },
    {
        name: "Mali",
        code: "ML",
        phoneCode: "+223",
        image: "/assets/countryFlags/ml.png",
    },
    {
        name: "Malta",
        code: "MT",
        phoneCode: "+356",
        image: "/assets/countryFlags/mt.png",
    },
    {
        name: "Marshall Islands",
        code: "MH",
        phoneCode: "+692",
        image: "/assets/countryFlags/mh.png",
    },
    {
        name: "Mauritania",
        code: "MR",
        phoneCode: "+222",
        image: "/assets/countryFlags/mr.png",
    },
    {
        name: "Mauritius",
        code: "MU",
        phoneCode: "+230",
        image: "/assets/countryFlags/mu.png",
    },
    {
        name: "Mexico",
        code: "MX",
        phoneCode: "+52",
        image: "/assets/countryFlags/mx.png",
    },
    {
        name: "Micronesia",
        code: "FM",
        phoneCode: "+691",
        image: "/assets/countryFlags/fm.png",
    },
    {
        name: "Moldova",
        code: "MD",
        phoneCode: "+373",
        image: "/assets/countryFlags/md.png",
    },
    {
        name: "Monaco",
        code: "MC",
        phoneCode: "+377",
        image: "/assets/countryFlags/mc.png",
    },
    {
        name: "Mongolia",
        code: "MN",
        phoneCode: "+976",
        image: "/assets/countryFlags/mn.png",
    },
    {
        name: "Montenegro",
        code: "ME",
        phoneCode: "+382",
        image: "/assets/countryFlags/me.png",
    },
    {
        name: "Morocco",
        code: "MA",
        phoneCode: "+212",
        image: "/assets/countryFlags/ma.png",
    },
    {
        name: "Mozambique",
        code: "MZ",
        phoneCode: "+258",
        image: "/assets/countryFlags/mz.png",
    },
    {
        name: "Myanmar",
        code: "MM",
        phoneCode: "+95",
        image: "/assets/countryFlags/mm.png",
    },
    {
        name: "Namibia",
        code: "NA",
        phoneCode: "+264",
        image: "/assets/countryFlags/na.png",
    },
    {
        name: "Nauru",
        code: "NR",
        phoneCode: "+674",
        image: "/assets/countryFlags/nr.png",
    },
    {
        name: "Nepal",
        code: "NP",
        phoneCode: "+977",
        image: "/assets/countryFlags/np.png",
    },
    {
        name: "Netherlands",
        code: "NL",
        phoneCode: "+31",
        image: "/assets/countryFlags/nl.png",
    },
    {
        name: "New Zealand",
        code: "NZ",
        phoneCode: "+64",
        image: "/assets/countryFlags/nz.png",
    },
    {
        name: "Nicaragua",
        code: "NI",
        phoneCode: "+505",
        image: "/assets/countryFlags/ni.png",
    },
    {
        name: "Niger",
        code: "NE",
        phoneCode: "+227",
        image: "/assets/countryFlags/ne.png",
    },
    {
        name: "Nigeria",
        code: "NG",
        phoneCode: "+234",
        image: "/assets/countryFlags/ng.png",
    },
    {
        name: "North Macedonia",
        code: "MK",
        phoneCode: "+389",
        image: "/assets/countryFlags/mk.png",
    },
    {
        name: "Norway",
        code: "NO",
        phoneCode: "+47",
        image: "/assets/countryFlags/no.png",
    },
    {
        name: "Oman",
        code: "OM",
        phoneCode: "+968",
        image: "/assets/countryFlags/om.png",
    },
    {
        name: "Pakistan",
        code: "PK",
        phoneCode: "+92",
        image: "/assets/countryFlags/pk.png",
    },
    {
        name: "Palau",
        code: "PW",
        phoneCode: "+680",
        image: "/assets/countryFlags/pw.png",
    },
    {
        name: "Palestine",
        code: "PS",
        phoneCode: "+970",
        image: "/assets/countryFlags/ps.png",
    },
    {
        name: "Panama",
        code: "PA",
        phoneCode: "+507",
        image: "/assets/countryFlags/pa.png",
    },
    {
        name: "Papua New Guinea",
        code: "PG",
        phoneCode: "+675",
        image: "/assets/countryFlags/pg.png",
    },
    {
        name: "Paraguay",
        code: "PY",
        phoneCode: "+595",
        image: "/assets/countryFlags/py.png",
    },
    {
        name: "Peru",
        code: "PE",
        phoneCode: "+51",
        image: "/assets/countryFlags/pe.png",
    },
    {
        name: "Philippines",
        code: "PH",
        phoneCode: "+63",
        image: "/assets/countryFlags/ph.png",
    },
    {
        name: "Poland",
        code: "PL",
        phoneCode: "+48",
        image: "/assets/countryFlags/pl.png",
    },
    {
        name: "Portugal",
        code: "PT",
        phoneCode: "+351",
        image: "/assets/countryFlags/pt.png",
    },
    {
        name: "Qatar",
        code: "QA",
        phoneCode: "+974",
        image: "/assets/countryFlags/qa.png",
    },
    {
        name: "Romania",
        code: "RO",
        phoneCode: "+40",
        image: "/assets/countryFlags/ro.png",
    },
    {
        name: "Russia",
        code: "RU",
        phoneCode: "+7",
        image: "/assets/countryFlags/ru.png",
    },
    {
        name: "Rwanda",
        code: "RW",
        phoneCode: "+250",
        image: "/assets/countryFlags/rw.png",
    },
    {
        name: "Saint Kitts and Nevis",
        code: "KN",
        phoneCode: "+1869",
        image: "/assets/countryFlags/kn.png",
    },
    {
        name: "Saint Lucia",
        code: "LC",
        phoneCode: "+1758",
        image: "/assets/countryFlags/lc.png",
    },
    {
        name: "Saint Vincent and the Grenadines",
        code: "VC",
        phoneCode: "+1784",
        image: "/assets/countryFlags/vc.png",
    },
    {
        name: "Samoa",
        code: "WS",
        phoneCode: "+685",
        image: "/assets/countryFlags/ws.png",
    },
    {
        name: "San Marino",
        code: "SM",
        phoneCode: "+378",
        image: "/assets/countryFlags/sm.png",
    },
    {
        name: "Sao Tome and Principe",
        code: "ST",
        phoneCode: "+239",
        image: "/assets/countryFlags/st.png",
    },
    {
        name: "Saudi Arabia",
        code: "SA",
        phoneCode: "+966",
        image: "/assets/countryFlags/sa.png",
    },
    {
        name: "Senegal",
        code: "SN",
        phoneCode: "+221",
        image: "/assets/countryFlags/sn.png",
    },
    {
        name: "Serbia",
        code: "RS",
        phoneCode: "+381",
        image: "/assets/countryFlags/rs.png",
    },
    {
        name: "Seychelles",
        code: "SC",
        phoneCode: "+248",
        image: "/assets/countryFlags/sc.png",
    },
    {
        name: "Sierra Leone",
        code: "SL",
        phoneCode: "+232",
        image: "/assets/countryFlags/sl.png",
    },
    {
        name: "Singapore",
        code: "SG",
        phoneCode: "+65",
        image: "/assets/countryFlags/sg.png",
    },
    {
        name: "Slovakia",
        code: "SK",
        phoneCode: "+421",
        image: "/assets/countryFlags/sk.png",
    },
    {
        name: "Slovenia",
        code: "SI",
        phoneCode: "+386",
        image: "/assets/countryFlags/si.png",
    },
    {
        name: "Solomon Islands",
        code: "SB",
        phoneCode: "+677",
        image: "/assets/countryFlags/sb.png",
    },
    {
        name: "Somalia",
        code: "SO",
        phoneCode: "+252",
        image: "/assets/countryFlags/so.png",
    },
    {
        name: "South Africa",
        code: "ZA",
        phoneCode: "+27",
        image: "/assets/countryFlags/za.png",
    },
    {
        name: "South Sudan",
        code: "SS",
        phoneCode: "+211",
        image: "/assets/countryFlags/ss.png",
    },
    {
        name: "Spain",
        code: "ES",
        phoneCode: "+34",
        image: "/assets/countryFlags/es.png",
    },
    {
        name: "Sri Lanka",
        code: "LK",
        phoneCode: "+94",
        image: "/assets/countryFlags/lk.png",
    },
    {
        name: "Sudan",
        code: "SD",
        phoneCode: "+249",
        image: "/assets/countryFlags/sd.png",
    },
    {
        name: "Suriname",
        code: "SR",
        phoneCode: "+597",
        image: "/assets/countryFlags/sr.png",
    },
    {
        name: "Sweden",
        code: "SE",
        phoneCode: "+46",
        image: "/assets/countryFlags/se.png",
    },
    {
        name: "Switzerland",
        code: "CH",
        phoneCode: "+41",
        image: "/assets/countryFlags/ch.png",
    },
    {
        name: "Syria",
        code: "SY",
        phoneCode: "+963",
        image: "/assets/countryFlags/sy.png",
    },
    {
        name: "Taiwan",
        code: "TW",
        phoneCode: "+886",
        image: "/assets/countryFlags/tw.png",
    },
    {
        name: "Tajikistan",
        code: "TJ",
        phoneCode: "+992",
        image: "/assets/countryFlags/tj.png",
    },
    {
        name: "Tanzania",
        code: "TZ",
        phoneCode: "+255",
        image: "/assets/countryFlags/tz.png",
    },
    {
        name: "Thailand",
        code: "TH",
        phoneCode: "+66",
        image: "/assets/countryFlags/th.png",
    },
    {
        name: "Timor-Leste",
        code: "TL",
        phoneCode: "+670",
        image: "/assets/countryFlags/tl.png",
    },
    {
        name: "Togo",
        code: "TG",
        phoneCode: "+228",
        image: "/assets/countryFlags/tg.png",
    },
    {
        name: "Tonga",
        code: "TO",
        phoneCode: "+676",
        image: "/assets/countryFlags/to.png",
    },
    {
        name: "Trinidad and Tobago",
        code: "TT",
        phoneCode: "+1868",
        image: "/assets/countryFlags/tt.png",
    },
    {
        name: "Tunisia",
        code: "TN",
        phoneCode: "+216",
        image: "/assets/countryFlags/tn.png",
    },
    {
        name: "Turkey",
        code: "TR",
        phoneCode: "+90",
        image: "/assets/countryFlags/tr.png",
    },
    {
        name: "Turkmenistan",
        code: "TM",
        phoneCode: "+993",
        image: "/assets/countryFlags/tm.png",
    },
    {
        name: "Tuvalu",
        code: "TV",
        phoneCode: "+688",
        image: "/assets/countryFlags/tv.png",
    },
    {
        name: "Uganda",
        code: "UG",
        phoneCode: "+256",
        image: "/assets/countryFlags/ug.png",
    },
    {
        name: "Ukraine",
        code: "UA",
        phoneCode: "+380",
        image: "/assets/countryFlags/ua.png",
    },
    {
        name: "United Arab Emirates",
        code: "AE",
        phoneCode: "+971",
        image: "/assets/countryFlags/ae.png",
    },
    {
        name: "United Kingdom",
        code: "GB",
        phoneCode: "+44",
        image: "/assets/countryFlags/gb.png",
    },
    {
        name: "United States",
        code: "US",
        phoneCode: "+1",
        image: "/assets/countryFlags/us.png",
    },
    {
        name: "Uruguay",
        code: "UY",
        phoneCode: "+598",
        image: "/assets/countryFlags/uy.png",
    },
    {
        name: "Uzbekistan",
        code: "UZ",
        phoneCode: "+998",
        image: "/assets/countryFlags/uz.png",
    },
    {
        name: "Vanuatu",
        code: "VU",
        phoneCode: "+678",
        image: "/assets/countryFlags/vu.png",
    },
    {
        name: "Vatican City",
        code: "VA",
        phoneCode: "+39",
        image: "/assets/countryFlags/va.png",
    },
    {
        name: "Venezuela",
        code: "VE",
        phoneCode: "+58",
        image: "/assets/countryFlags/ve.png",
    },
    {
        name: "Vietnam",
        code: "VN",
        phoneCode: "+84",
        image: "/assets/countryFlags/vn.png",
    },
    {
        name: "Yemen",
        code: "YE",
        phoneCode: "+967",
        image: "/assets/countryFlags/ye.png",
    },
    {
        name: "Zambia",
        code: "ZM",
        phoneCode: "+260",
        image: "/assets/countryFlags/zm.png",
    },
    {
        name: "Zimbabwe",
        code: "ZW",
        phoneCode: "+263",
        image: "/assets/countryFlags/zw.png",
    },
];