import React from "react";
import NavBar from "../components/CommonComponents/Navbar";
import Footer from "../components/CommonComponents/Footer";
import BrandsCarouselSetion from "../components/creators/BrandsCarouselSection";
import MonetizeSalesSection from "../components/creators/MonetizeSalesSection";
import PremiumFeaturesSection from "../components/creators/PremiumFeaturesSection";
import ContactUs from "../components/CommonComponents/ContactUs";
import FaqSection from "../components/creators/FaqSection";
import CreatorsCarouselSection from "../components/creators/CreatorsCarouselSection";
import HeroSection from "../components/creators/HeroSection";
import FaymstoreCreatorsSection from "../components/creators/FaymstoreCreatorsSection";
import QrCode from "../components/creators/QrCode";

const CreatorPage = () => {
    return (
        <div>
            <QrCode />
            <NavBar />
            <HeroSection />
            <MonetizeSalesSection />
            <BrandsCarouselSetion />
            <PremiumFeaturesSection />
            <FaymstoreCreatorsSection />
            <CreatorsCarouselSection />
            <FaqSection />
            <ContactUs />
            <Footer />
        </div>
    );
};

export default CreatorPage;
