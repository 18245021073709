import React from "react";
import FaqSec from "../components/creators/FaqSection";

const FaqsPage = () => {
    return (
        <div>
            <FaqSec />
        </div>
    );
};

export default FaqsPage;
