import "./tnc.css";
function TermsAndConditions() {
    return (
        <div className="container">
            <div className="head">
                <strong>
                    <span data-custom-class="title">PRIVACY POLICY</span>
                </strong>
            </div>
            <div>&nbsp;</div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <br />
            <div>
                <strong>
                    <span data-custom-class="subtitle">
                        Last updated&nbsp;September 01, 2023
                    </span>
                </strong>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>
                <span data-custom-class="body_text">
                    This privacy notice for&nbsp;POSTFAYM TECHNOLOGIES PRIVATE
                    LIMITED&nbsp;(doing business
                    as&nbsp;Faym&nbsp;)&nbsp;(&nbsp;"<strong>we</strong>," "
                    <strong>us</strong>," or "<strong>our</strong>"&nbsp;
                </span>
                <span data-custom-class="body_text">
                    ), describes how and why we might collect, store, use,
                    and/or share (&nbsp;"<strong>process</strong>"&nbsp;) your
                    information when you use our services (&nbsp;"
                    <strong>Services</strong>"&nbsp;), such as when you:
                </span>
            </div>
            <ul>
                <li>
                    <span data-custom-class="body_text">
                        Visit our website&nbsp;at&nbsp;
                        <a
                            href="https://faym.co/"
                            target="_blank"
                            rel="noopener"
                            data-custom-class="link"
                        >
                            https://faym.co
                        </a>
                        &nbsp;, or any website of ours that links to this
                        privacy notice
                    </span>
                </li>
            </ul>
            <div>
                <div>&nbsp;</div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            Engage with us in other related ways, including any
                            sales, marketing, or events
                        </span>
                    </li>
                </ul>
                <div>
                    <span data-custom-class="body_text">
                        <strong>Questions or concerns?&nbsp;</strong>Reading
                        this privacy notice will help you understand your
                        privacy rights and choices. If you do not agree with our
                        policies and practices, please do not use our Services.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <strong>
                        <span data-custom-class="heading_1">
                            SUMMARY OF KEY POINTS
                        </span>
                    </strong>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>
                            <em>
                                This summary provides key points from our
                                privacy notice, but you can find out more
                                details about any of these topics by clicking
                                the link following each key point or by using
                                our&nbsp;
                            </em>
                        </strong>
                    </span>
                    <a href="#toc" data-custom-class="link">
                        <span data-custom-class="body_text">
                            <strong>
                                <em>table of contents</em>
                            </strong>
                        </span>
                    </a>
                    <span data-custom-class="body_text">
                        <strong>
                            <em>
                                &nbsp;below to find the section you are looking
                                for.
                            </em>
                        </strong>
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>
                            What personal information do we process?
                        </strong>
                        &nbsp;When you visit, use, or navigate our Services, we
                        may process personal information depending on how you
                        interact with us and the Services, the choices you make,
                        and the products and features you use. Learn more
                        about&nbsp;
                    </span>
                    <a href="#personalinfo" data-custom-class="link">
                        <span data-custom-class="body_text">
                            personal information you disclose to us
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>
                            Do we process any sensitive personal information?
                        </strong>
                        &nbsp;We do not process sensitive personal information.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>
                            Do we receive any information from third parties?
                        </strong>
                        &nbsp;We do not receive any information from third
                        parties.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>How do we process your information?</strong>
                        &nbsp;We process your information to provide, improve,
                        and administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes
                        with your consent. We process your information only when
                        we have a valid legal reason to do so. Learn more
                        about&nbsp;
                    </span>
                    <a href="#infouse" data-custom-class="link">
                        <span data-custom-class="body_text">
                            how we process your information
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>
                            In what situations and with which&nbsp;parties do we
                            share personal information?
                        </strong>
                        &nbsp;We may share information in specific situations
                        and with specific&nbsp;third parties. Learn more
                        about&nbsp;
                    </span>
                    <a href="#whoshare" data-custom-class="link">
                        <span data-custom-class="body_text">
                            when and with whom we share your personal
                            information
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>How do we keep your information safe?</strong>
                        &nbsp;We have&nbsp;organizational&nbsp;and technical
                        processes and procedures in place to protect your
                        personal information. However, no electronic
                        transmission over the internet or information storage
                        technology can be guaranteed to be 100% secure, so we
                        cannot promise or guarantee that hackers,
                        cybercriminals, or other&nbsp;unauthorized&nbsp;third
                        parties will not be able to defeat our security and
                        improperly collect, access, steal, or modify your
                        information. Learn more about&nbsp;
                    </span>
                    <a href="#infosafe" data-custom-class="link">
                        <span data-custom-class="body_text">
                            how we keep your information safe
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>What are your rights?</strong>&nbsp;Depending on
                        where you are located geographically, the applicable
                        privacy law may mean you have certain rights regarding
                        your personal information. Learn more about&nbsp;
                    </span>
                    <a href="#privacyrights" data-custom-class="link">
                        <span data-custom-class="body_text">
                            your privacy rights
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>How do you exercise your rights?</strong>
                        &nbsp;The easiest way to exercise your rights is
                        by&nbsp;submitting a&nbsp;
                    </span>
                    <a
                        href="https://app.termly.io/notify/a310e9f2-54ee-4fca-b919-92d3103cedd2"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-custom-class="link"
                    >
                        <span data-custom-class="body_text">
                            data subject access request
                        </span>
                    </a>
                    <span data-custom-class="body_text">
                        , or by contacting us. We will consider and act upon any
                        request in accordance with applicable data protection
                        laws.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        Want to learn more about what we do with any information
                        we collect?&nbsp;
                    </span>
                    <a href="#toc" data-custom-class="link">
                        <span data-custom-class="body_text">
                            Review the privacy notice in full
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div id="toc">
                    <strong>
                        <span data-custom-class="heading_1">
                            TABLE OF CONTENTS
                        </span>
                    </strong>
                </div>
                <div>&nbsp;</div>
                <div>
                    <a href="#infocollect" data-custom-class="link">
                        1. WHAT INFORMATION DO WE COLLECT?
                    </a>
                </div>
                <div>
                    <a href="#infouse" data-custom-class="link">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                    </a>
                </div>
                <div>
                    <a href="#whoshare" data-custom-class="link">
                        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                        INFORMATION?
                    </a>
                </div>
                <div>
                    <a href="#3pwebsites" data-custom-class="link">
                        4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                    </a>
                </div>
                <div>
                    <a href="#sociallogins" data-custom-class="link">
                        5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </a>
                </div>
                <div>
                    <a href="#inforetain" data-custom-class="link">
                        6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                </div>
                <div>
                    <a href="#infosafe" data-custom-class="link">
                        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </a>
                </div>
                <div>
                    <a href="#privacyrights" data-custom-class="link">
                        8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                </div>
                <div>
                    <a href="#DNT" data-custom-class="link">
                        9. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </a>
                </div>
                <div>
                    <a href="#policyupdates" data-custom-class="link">
                        10. DO WE MAKE UPDATES TO THIS NOTICE?
                    </a>
                </div>
                <div>
                    <a href="#contact" data-custom-class="link">
                        11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </a>
                </div>
                <div>
                    <a href="#request" data-custom-class="link">
                        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                        COLLECT FROM YOU?
                    </a>
                </div>
                <div>
                    <a href="#return" data-custom-class="link">
                        13. REFUND AND RETURN POLICY?
                    </a>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div id="infocollect">
                    <span id="control">
                        <strong>
                            <span data-custom-class="heading_1">
                                1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                        </strong>
                    </span>
                </div>
                <div>&nbsp;</div>
                <div id="personalinfo">
                    <span data-custom-class="heading_2">
                        <strong>Personal information you disclose to us</strong>
                    </span>
                </div>
                <div>
                    <div>&nbsp;</div>
                    <div>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>In Short:</em>
                            </strong>
                        </span>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>&nbsp;</em>
                            </strong>
                            <em>
                                We collect personal information that you provide
                                to us.
                            </em>
                        </span>
                    </div>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        We collect personal information that you voluntarily
                        provide to us when you&nbsp;register on the
                        Services,&nbsp;
                    </span>
                    <span data-custom-class="body_text">
                        express an interest in obtaining information about us or
                        our products and Services, when you participate in
                        activities on the Services, or otherwise when you
                        contact us.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>Personal Information Provided by You.</strong>
                        &nbsp;The personal information that we collect depends
                        on the context of your interactions with us and the
                        Services, the choices you make, and the products and
                        features you use. The personal information we collect
                        may include the following:
                    </span>
                </div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">names</span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">phone numbers</span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            email addresses
                        </span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <div id="sensitiveinfo">
                    <span data-custom-class="body_text">
                        <strong>Sensitive Information.</strong>&nbsp;We do not
                        process sensitive information.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>Social Media Login Data.&nbsp;</strong>We may
                        provide you with the option to register with us using
                        your existing social media account details, like your
                        Facebook, Twitter, or other social media account. If you
                        choose to register in this way, we will collect the
                        information described in the section called&nbsp;"
                        <a href="#sociallogins" data-custom-class="link">
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </a>
                        "&nbsp;below.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        All personal information that you provide to us must be
                        true, complete, and accurate, and you must notify us of
                        any changes to such personal information.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="heading_2">
                        <strong>Information automatically collected</strong>
                    </span>
                </div>
                <div>
                    <div>&nbsp;</div>
                    <div>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>In Short:</em>
                            </strong>
                        </span>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>&nbsp;</em>
                            </strong>
                            <em>
                                Some information &mdash; such as your Internet
                                Protocol (IP) address and/or browser and device
                                characteristics &mdash; is collected
                                automatically when you visit our Services.
                            </em>
                        </span>
                    </div>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        We automatically collect certain information when you
                        visit, use, or navigate the Services. This information
                        does not reveal your specific identity (like your name
                        or contact information) but may include device and usage
                        information, such as your IP address, browser and device
                        characteristics, operating system, language preferences,
                        referring URLs, device name, country, location,
                        information about how and when you use our Services, and
                        other technical information. This information is
                        primarily needed to maintain the security and operation
                        of our Services, and for our internal analytics and
                        reporting purposes.
                    </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        The information we collect includes:
                    </span>
                </div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            <em>Log and Usage Data.</em>&nbsp;Log and usage data
                            is service-related, diagnostic, usage, and
                            performance information our servers automatically
                            collect when you access or use our Services and
                            which we record in log files. Depending on how you
                            interact with us, this log data may include your IP
                            address, device information, browser type, and
                            settings and information about your activity in the
                            Services&nbsp;(such as the date/time stamps
                            associated with your usage, pages and files viewed,
                            searches, and other actions you take such as which
                            features you use), device event information (such as
                            system activity, error reports (sometimes
                            called&nbsp;"crash dumps"), and hardware settings).
                        </span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            <em>Device Data.</em>&nbsp;We collect device data
                            such as information about your computer, phone,
                            tablet, or other device you use to access the
                            Services. Depending on the device used, this device
                            data may include information such as your IP address
                            (or proxy server), device and application
                            identification numbers, location, browser type,
                            hardware model, Internet service provider and/or
                            mobile carrier, operating system, and system
                            configuration information.
                        </span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            <em>Location Data.</em>&nbsp;We collect location
                            data such as information about your device's
                            location, which can be either precise or imprecise.
                            How much information we collect depends on the type
                            and settings of the device you use to access the
                            Services. For example, we may use GPS and other
                            technologies to collect geolocation data that tells
                            us your current location (based on your IP address).
                            You can opt out of allowing us to collect this
                            information either by refusing access to the
                            information or by disabling your Location setting on
                            your device. However, if you choose to opt out, you
                            may not be able to use certain aspects of the
                            Services.
                        </span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <div id="infouse">
                    <span id="control">
                        <strong>
                            <span data-custom-class="heading_1">
                                2. HOW DO WE PROCESS YOUR INFORMATION?
                            </span>
                        </strong>
                    </span>
                </div>
                <div>
                    <div>&nbsp;</div>
                    <div>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>In Short:&nbsp;</em>
                            </strong>
                            <em>
                                We process your information to provide, improve,
                                and administer our Services, communicate with
                                you, for security and fraud prevention, and to
                                comply with law. We may also process your
                                information for other purposes with your
                                consent.
                            </em>
                        </span>
                    </div>
                </div>
                <div>&nbsp;</div>
                <div>
                    <span data-custom-class="body_text">
                        <strong>
                            We process your personal information for a variety
                            of reasons, depending on how you interact with our
                            Services, including:
                        </strong>
                    </span>
                </div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            <strong>
                                To facilitate account creation and
                                authentication and otherwise manage user
                                accounts.&nbsp;
                            </strong>
                            We may process your information so you can create
                            and log in to your account, as well as keep your
                            account in working order.
                        </span>
                    </li>
                </ul>
                <div>&nbsp;</div>
                <ul>
                    <li>
                        <span data-custom-class="body_text">
                            <strong>
                                To deliver and facilitate delivery of services
                                to the user.&nbsp;
                            </strong>
                            We may process your information to provide you with
                            the requested service.
                        </span>
                    </li>
                </ul>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>&nbsp;</div>
                                    <ul>
                                        <li>
                                            <span data-custom-class="body_text">
                                                <strong>
                                                    To&nbsp;fulfill&nbsp;and
                                                    manage your orders.&nbsp;
                                                </strong>
                                                We may process your information
                                                to&nbsp;fulfill&nbsp;and manage
                                                your orders, payments, returns,
                                                and exchanges made through the
                                                Services.
                                            </span>
                                        </li>
                                    </ul>
                                    <div>
                                        <p>&nbsp;</p>
                                        <ul>
                                            <li>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        To enable user-to-user
                                                        communications.&nbsp;
                                                    </strong>
                                                    We may process your
                                                    information if you choose to
                                                    use any of our offerings
                                                    that allow for communication
                                                    with another user.
                                                </span>
                                            </li>
                                        </ul>
                                        <p>&nbsp;</p>
                                        <ul>
                                            <li>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        To send you marketing
                                                        and promotional
                                                        communications.&nbsp;
                                                    </strong>
                                                    We may process the personal
                                                    information you send to us
                                                    for our marketing purposes,
                                                    if this is in accordance
                                                    with your marketing
                                                    preferences. You can opt out
                                                    of our marketing emails at
                                                    any time. For more
                                                    information,
                                                    see&nbsp;"&nbsp;
                                                </span>
                                                <a href="#privacyrights">
                                                    <span data-custom-class="link">
                                                        WHAT ARE YOUR PRIVACY
                                                        RIGHTS?
                                                    </span>
                                                </a>
                                                &nbsp;
                                                <span data-custom-class="body_text">
                                                    "&nbsp;below.
                                                </span>
                                            </li>
                                        </ul>
                                        <div>
                                            <div>&nbsp;</div>
                                            <ul>
                                                <li>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            To deliver targeted
                                                            advertising to
                                                            you.&nbsp;
                                                        </strong>
                                                        We may process your
                                                        information to develop
                                                        and
                                                        display&nbsp;personalized&nbsp;content
                                                        and advertising tailored
                                                        to your interests,
                                                        location, and more.
                                                    </span>
                                                </li>
                                            </ul>
                                            <div>
                                                <div>&nbsp;</div>
                                                <ul>
                                                    <li>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                To post
                                                                testimonials.
                                                            </strong>
                                                            &nbsp;We post
                                                            testimonials on our
                                                            Services that may
                                                            contain personal
                                                            information.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <div>
                                                        <div>&nbsp;</div>
                                                        <ul>
                                                            <li>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To
                                                                        administer
                                                                        prize
                                                                        draws
                                                                        and
                                                                        competitions.
                                                                    </strong>
                                                                    &nbsp;We may
                                                                    process your
                                                                    information
                                                                    to
                                                                    administer
                                                                    prize draws
                                                                    and
                                                                    competitions.
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div>&nbsp;</div>
                                                        <ul>
                                                            <li>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To
                                                                        evaluate
                                                                        and
                                                                        improve
                                                                        our
                                                                        Services,
                                                                        products,
                                                                        marketing,
                                                                        and your
                                                                        experience.
                                                                    </strong>
                                                                    &nbsp;We may
                                                                    process your
                                                                    information
                                                                    when we
                                                                    believe it
                                                                    is necessary
                                                                    to identify
                                                                    usage
                                                                    trends,
                                                                    determine
                                                                    the
                                                                    effectiveness
                                                                    of our
                                                                    promotional
                                                                    campaigns,
                                                                    and to
                                                                    evaluate and
                                                                    improve our
                                                                    Services,
                                                                    products,
                                                                    marketing,
                                                                    and your
                                                                    experience.
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div>&nbsp;</div>
                                                        <ul>
                                                            <li>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To
                                                                        identify
                                                                        usage
                                                                        trends.
                                                                    </strong>
                                                                    &nbsp;We may
                                                                    process
                                                                    information
                                                                    about how
                                                                    you use our
                                                                    Services to
                                                                    better
                                                                    understand
                                                                    how they are
                                                                    being used
                                                                    so we can
                                                                    improve
                                                                    them.
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <div>&nbsp;</div>
                                                            <ul>
                                                                <li>
                                                                    <span data-custom-class="body_text">
                                                                        <strong>
                                                                            To
                                                                            determine
                                                                            the
                                                                            effectiveness
                                                                            of
                                                                            our
                                                                            marketing
                                                                            and
                                                                            promotional
                                                                            campaigns.
                                                                        </strong>
                                                                        &nbsp;We
                                                                        may
                                                                        process
                                                                        your
                                                                        information
                                                                        to
                                                                        better
                                                                        understand
                                                                        how to
                                                                        provide
                                                                        marketing
                                                                        and
                                                                        promotional
                                                                        campaigns
                                                                        that are
                                                                        most
                                                                        relevant
                                                                        to you.
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            &nbsp;
                                                                        </div>
                                                                        <div id="whoshare">
                                                                            <span id="control">
                                                                                <strong>
                                                                                    <span data-custom-class="heading_1">
                                                                                        3.
                                                                                        WHEN
                                                                                        AND
                                                                                        WITH
                                                                                        WHOM
                                                                                        DO
                                                                                        WE
                                                                                        SHARE
                                                                                        YOUR
                                                                                        PERSONAL
                                                                                        INFORMATION?
                                                                                    </span>
                                                                                </strong>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>
                                                                                        In
                                                                                        Short:
                                                                                    </em>
                                                                                </strong>
                                                                                &nbsp;
                                                                                <em>
                                                                                    We
                                                                                    may
                                                                                    share
                                                                                    information
                                                                                    in
                                                                                    specific
                                                                                    situations
                                                                                    described
                                                                                    in
                                                                                    this
                                                                                    section
                                                                                    and/or
                                                                                    with
                                                                                    the
                                                                                    following&nbsp;third
                                                                                    parties.
                                                                                </em>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            <span data-custom-class="body_text">
                                                                                We&nbsp;may
                                                                                need
                                                                                to
                                                                                share
                                                                                your
                                                                                personal
                                                                                information
                                                                                in
                                                                                the
                                                                                following
                                                                                situations:
                                                                            </span>
                                                                        </div>
                                                                        <ul>
                                                                            <li>
                                                                                <span data-custom-class="body_text">
                                                                                    <strong>
                                                                                        Business
                                                                                        Transfers.
                                                                                    </strong>
                                                                                    &nbsp;We
                                                                                    may
                                                                                    share
                                                                                    or
                                                                                    transfer
                                                                                    your
                                                                                    information
                                                                                    in
                                                                                    connection
                                                                                    with,
                                                                                    or
                                                                                    during
                                                                                    negotiations
                                                                                    of,
                                                                                    any
                                                                                    merger,
                                                                                    sale
                                                                                    of
                                                                                    company
                                                                                    assets,
                                                                                    financing,
                                                                                    or
                                                                                    acquisition
                                                                                    of
                                                                                    all
                                                                                    or
                                                                                    a
                                                                                    portion
                                                                                    of
                                                                                    our
                                                                                    business
                                                                                    to
                                                                                    another
                                                                                    company.
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        <div>
                                                                            <div>
                                                                                <div>
                                                                                    <div>
                                                                                        <div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <strong>
                                                                                                    <span id="3pwebsites">
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            4.
                                                                                                            WHAT
                                                                                                            IS
                                                                                                            OUR
                                                                                                            STANCE
                                                                                                            ON
                                                                                                            THIRD-PARTY
                                                                                                            WEBSITES?
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </strong>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <em>
                                                                                                            In
                                                                                                            Short:
                                                                                                        </em>
                                                                                                    </strong>
                                                                                                    <em>
                                                                                                        &nbsp;We
                                                                                                        are
                                                                                                        not
                                                                                                        responsible
                                                                                                        for
                                                                                                        the
                                                                                                        safety
                                                                                                        of
                                                                                                        any
                                                                                                        information
                                                                                                        that
                                                                                                        you
                                                                                                        share
                                                                                                        with
                                                                                                        third
                                                                                                        parties
                                                                                                        that
                                                                                                        we
                                                                                                        may
                                                                                                        link
                                                                                                        to
                                                                                                        or
                                                                                                        who
                                                                                                        advertise
                                                                                                        on
                                                                                                        our
                                                                                                        Services,
                                                                                                        but
                                                                                                        are
                                                                                                        not
                                                                                                        affiliated
                                                                                                        with,
                                                                                                        our
                                                                                                        Services.
                                                                                                    </em>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    The
                                                                                                    Services&nbsp;may
                                                                                                    link
                                                                                                    to
                                                                                                    third-party
                                                                                                    websites,
                                                                                                    online
                                                                                                    services,
                                                                                                    or
                                                                                                    mobile
                                                                                                    applications
                                                                                                    and/or
                                                                                                    contain
                                                                                                    advertisements
                                                                                                    from
                                                                                                    third
                                                                                                    parties
                                                                                                    that
                                                                                                    are
                                                                                                    not
                                                                                                    affiliated
                                                                                                    with
                                                                                                    us
                                                                                                    and
                                                                                                    which
                                                                                                    may
                                                                                                    link
                                                                                                    to
                                                                                                    other
                                                                                                    websites,
                                                                                                    services,
                                                                                                    or
                                                                                                    applications.
                                                                                                    Accordingly,
                                                                                                    we
                                                                                                    do
                                                                                                    not
                                                                                                    make
                                                                                                    any
                                                                                                    guarantee
                                                                                                    regarding
                                                                                                    any
                                                                                                    such
                                                                                                    third
                                                                                                    parties,
                                                                                                    and
                                                                                                    we
                                                                                                    will
                                                                                                    not
                                                                                                    be
                                                                                                    liable
                                                                                                    for
                                                                                                    any
                                                                                                    loss
                                                                                                    or
                                                                                                    damage
                                                                                                    caused
                                                                                                    by
                                                                                                    the
                                                                                                    use
                                                                                                    of
                                                                                                    such
                                                                                                    third-party
                                                                                                    websites,
                                                                                                    services,
                                                                                                    or
                                                                                                    applications.
                                                                                                    The
                                                                                                    inclusion
                                                                                                    of
                                                                                                    a
                                                                                                    link
                                                                                                    towards
                                                                                                    a
                                                                                                    third-party
                                                                                                    website,
                                                                                                    service,
                                                                                                    or
                                                                                                    application
                                                                                                    does
                                                                                                    not
                                                                                                    imply
                                                                                                    an
                                                                                                    endorsement
                                                                                                    by
                                                                                                    us.
                                                                                                    We
                                                                                                    cannot
                                                                                                    guarantee
                                                                                                    the
                                                                                                    safety
                                                                                                    and
                                                                                                    privacy
                                                                                                    of
                                                                                                    data
                                                                                                    you
                                                                                                    provide
                                                                                                    to
                                                                                                    any
                                                                                                    third
                                                                                                    parties.
                                                                                                    Any
                                                                                                    data
                                                                                                    collected
                                                                                                    by
                                                                                                    third
                                                                                                    parties
                                                                                                    is
                                                                                                    not
                                                                                                    covered
                                                                                                    by
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice.
                                                                                                    We
                                                                                                    are
                                                                                                    not
                                                                                                    responsible
                                                                                                    for
                                                                                                    the
                                                                                                    content
                                                                                                    or
                                                                                                    privacy
                                                                                                    and
                                                                                                    security
                                                                                                    practices
                                                                                                    and
                                                                                                    policies
                                                                                                    of
                                                                                                    any
                                                                                                    third
                                                                                                    parties,
                                                                                                    including
                                                                                                    other
                                                                                                    websites,
                                                                                                    services,
                                                                                                    or
                                                                                                    applications
                                                                                                    that
                                                                                                    may
                                                                                                    be
                                                                                                    linked
                                                                                                    to
                                                                                                    or
                                                                                                    from
                                                                                                    the
                                                                                                    Services.
                                                                                                    You
                                                                                                    should
                                                                                                    review
                                                                                                    the
                                                                                                    policies
                                                                                                    of
                                                                                                    such
                                                                                                    third
                                                                                                    parties
                                                                                                    and
                                                                                                    contact
                                                                                                    them
                                                                                                    directly
                                                                                                    to
                                                                                                    respond
                                                                                                    to
                                                                                                    your
                                                                                                    questions.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="sociallogins">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            5.
                                                                                                            HOW
                                                                                                            DO
                                                                                                            WE
                                                                                                            HANDLE
                                                                                                            YOUR
                                                                                                            SOCIAL
                                                                                                            LOGINS?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <em>
                                                                                                            In
                                                                                                            Short:&nbsp;
                                                                                                        </em>
                                                                                                    </strong>
                                                                                                    <em>
                                                                                                        If
                                                                                                        you
                                                                                                        choose
                                                                                                        to
                                                                                                        register
                                                                                                        or
                                                                                                        log
                                                                                                        in
                                                                                                        to
                                                                                                        our
                                                                                                        Services
                                                                                                        using
                                                                                                        a
                                                                                                        social
                                                                                                        media
                                                                                                        account,
                                                                                                        we
                                                                                                        may
                                                                                                        have
                                                                                                        access
                                                                                                        to
                                                                                                        certain
                                                                                                        information
                                                                                                        about
                                                                                                        you.
                                                                                                    </em>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    Our
                                                                                                    Services
                                                                                                    offer
                                                                                                    you
                                                                                                    the
                                                                                                    ability
                                                                                                    to
                                                                                                    register
                                                                                                    and
                                                                                                    log
                                                                                                    in
                                                                                                    using
                                                                                                    your
                                                                                                    third-party
                                                                                                    social
                                                                                                    media
                                                                                                    account
                                                                                                    details
                                                                                                    (like
                                                                                                    your
                                                                                                    Facebook
                                                                                                    or
                                                                                                    Twitter
                                                                                                    logins).
                                                                                                    Where
                                                                                                    you
                                                                                                    choose
                                                                                                    to
                                                                                                    do
                                                                                                    this,
                                                                                                    we
                                                                                                    will
                                                                                                    receive
                                                                                                    certain
                                                                                                    profile
                                                                                                    information
                                                                                                    about
                                                                                                    you
                                                                                                    from
                                                                                                    your
                                                                                                    social
                                                                                                    media
                                                                                                    provider.
                                                                                                    The
                                                                                                    profile
                                                                                                    information
                                                                                                    we
                                                                                                    receive
                                                                                                    may
                                                                                                    vary
                                                                                                    depending
                                                                                                    on
                                                                                                    the
                                                                                                    social
                                                                                                    media
                                                                                                    provider
                                                                                                    concerned,
                                                                                                    but
                                                                                                    will
                                                                                                    often
                                                                                                    include
                                                                                                    your
                                                                                                    name,
                                                                                                    email
                                                                                                    address,
                                                                                                    friends
                                                                                                    list,
                                                                                                    and
                                                                                                    profile
                                                                                                    picture,
                                                                                                    as
                                                                                                    well
                                                                                                    as
                                                                                                    other
                                                                                                    information
                                                                                                    you
                                                                                                    choose
                                                                                                    to
                                                                                                    make
                                                                                                    public
                                                                                                    on
                                                                                                    such
                                                                                                    a
                                                                                                    social
                                                                                                    media
                                                                                                    platform.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    We
                                                                                                    will
                                                                                                    use
                                                                                                    the
                                                                                                    information
                                                                                                    we
                                                                                                    receive
                                                                                                    only
                                                                                                    for
                                                                                                    the
                                                                                                    purposes
                                                                                                    that
                                                                                                    are
                                                                                                    described
                                                                                                    in
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    or
                                                                                                    that
                                                                                                    are
                                                                                                    otherwise
                                                                                                    made
                                                                                                    clear
                                                                                                    to
                                                                                                    you
                                                                                                    on
                                                                                                    the
                                                                                                    relevant
                                                                                                    Services.
                                                                                                    Please
                                                                                                    note
                                                                                                    that
                                                                                                    we
                                                                                                    do
                                                                                                    not
                                                                                                    control,
                                                                                                    and
                                                                                                    are
                                                                                                    not
                                                                                                    responsible
                                                                                                    for,
                                                                                                    other
                                                                                                    uses
                                                                                                    of
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    by
                                                                                                    your
                                                                                                    third-party
                                                                                                    social
                                                                                                    media
                                                                                                    provider.
                                                                                                    We
                                                                                                    recommend
                                                                                                    that
                                                                                                    you
                                                                                                    review
                                                                                                    their
                                                                                                    privacy
                                                                                                    notice
                                                                                                    to
                                                                                                    understand
                                                                                                    how
                                                                                                    they
                                                                                                    collect,
                                                                                                    use,
                                                                                                    and
                                                                                                    share
                                                                                                    your
                                                                                                    personal
                                                                                                    information,
                                                                                                    and
                                                                                                    how
                                                                                                    you
                                                                                                    can
                                                                                                    set
                                                                                                    your
                                                                                                    privacy
                                                                                                    preferences
                                                                                                    on
                                                                                                    their
                                                                                                    sites
                                                                                                    and
                                                                                                    apps.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="inforetain">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            6.
                                                                                                            HOW
                                                                                                            LONG
                                                                                                            DO
                                                                                                            WE
                                                                                                            KEEP
                                                                                                            YOUR
                                                                                                            INFORMATION?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <em>
                                                                                                            In
                                                                                                            Short:&nbsp;
                                                                                                        </em>
                                                                                                    </strong>
                                                                                                    <em>
                                                                                                        We
                                                                                                        keep
                                                                                                        your
                                                                                                        information
                                                                                                        for
                                                                                                        as
                                                                                                        long
                                                                                                        as
                                                                                                        necessary
                                                                                                        to&nbsp;fulfill&nbsp;the
                                                                                                        purposes
                                                                                                        outlined
                                                                                                        in
                                                                                                        this
                                                                                                        privacy
                                                                                                        notice
                                                                                                        unless
                                                                                                        otherwise
                                                                                                        required
                                                                                                        by
                                                                                                        law.
                                                                                                    </em>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    We
                                                                                                    will
                                                                                                    only
                                                                                                    keep
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    for
                                                                                                    as
                                                                                                    long
                                                                                                    as
                                                                                                    it
                                                                                                    is
                                                                                                    necessary
                                                                                                    for
                                                                                                    the
                                                                                                    purposes
                                                                                                    set
                                                                                                    out
                                                                                                    in
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice,
                                                                                                    unless
                                                                                                    a
                                                                                                    longer
                                                                                                    retention
                                                                                                    period
                                                                                                    is
                                                                                                    required
                                                                                                    or
                                                                                                    permitted
                                                                                                    by
                                                                                                    law
                                                                                                    (such
                                                                                                    as
                                                                                                    tax,
                                                                                                    accounting,
                                                                                                    or
                                                                                                    other
                                                                                                    legal
                                                                                                    requirements).&nbsp;No
                                                                                                    purpose
                                                                                                    in
                                                                                                    this
                                                                                                    notice
                                                                                                    will
                                                                                                    require
                                                                                                    us
                                                                                                    keeping
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    for
                                                                                                    longer
                                                                                                    than&nbsp;the
                                                                                                    period
                                                                                                    of
                                                                                                    time
                                                                                                    in
                                                                                                    which
                                                                                                    users
                                                                                                    have
                                                                                                    an
                                                                                                    account
                                                                                                    with
                                                                                                    us.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    When
                                                                                                    we
                                                                                                    have
                                                                                                    no
                                                                                                    ongoing
                                                                                                    legitimate
                                                                                                    business
                                                                                                    need
                                                                                                    to
                                                                                                    process
                                                                                                    your
                                                                                                    personal
                                                                                                    information,
                                                                                                    we
                                                                                                    will
                                                                                                    either
                                                                                                    delete
                                                                                                    or&nbsp;anonymize&nbsp;such
                                                                                                    information,
                                                                                                    or,
                                                                                                    if
                                                                                                    this
                                                                                                    is
                                                                                                    not
                                                                                                    possible
                                                                                                    (for
                                                                                                    example,
                                                                                                    because
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    has
                                                                                                    been
                                                                                                    stored
                                                                                                    in
                                                                                                    backup
                                                                                                    archives),
                                                                                                    then
                                                                                                    we
                                                                                                    will
                                                                                                    securely
                                                                                                    store
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    and
                                                                                                    isolate
                                                                                                    it
                                                                                                    from
                                                                                                    any
                                                                                                    further
                                                                                                    processing
                                                                                                    until
                                                                                                    deletion
                                                                                                    is
                                                                                                    possible.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="infosafe">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            7.
                                                                                                            HOW
                                                                                                            DO
                                                                                                            WE
                                                                                                            KEEP
                                                                                                            YOUR
                                                                                                            INFORMATION
                                                                                                            SAFE?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <em>
                                                                                                            In
                                                                                                            Short:&nbsp;
                                                                                                        </em>
                                                                                                    </strong>
                                                                                                    <em>
                                                                                                        We
                                                                                                        aim
                                                                                                        to
                                                                                                        protect
                                                                                                        your
                                                                                                        personal
                                                                                                        information
                                                                                                        through
                                                                                                        a
                                                                                                        system
                                                                                                        of&nbsp;organizational&nbsp;and
                                                                                                        technical
                                                                                                        security
                                                                                                        measures.
                                                                                                    </em>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    We
                                                                                                    have
                                                                                                    implemented
                                                                                                    appropriate
                                                                                                    and
                                                                                                    reasonable
                                                                                                    technical
                                                                                                    and&nbsp;organizational&nbsp;security
                                                                                                    measures
                                                                                                    designed
                                                                                                    to
                                                                                                    protect
                                                                                                    the
                                                                                                    security
                                                                                                    of
                                                                                                    any
                                                                                                    personal
                                                                                                    information
                                                                                                    we
                                                                                                    process.
                                                                                                    However,
                                                                                                    despite
                                                                                                    our
                                                                                                    safeguards
                                                                                                    and
                                                                                                    efforts
                                                                                                    to
                                                                                                    secure
                                                                                                    your
                                                                                                    information,
                                                                                                    no
                                                                                                    electronic
                                                                                                    transmission
                                                                                                    over
                                                                                                    the
                                                                                                    Internet
                                                                                                    or
                                                                                                    information
                                                                                                    storage
                                                                                                    technology
                                                                                                    can
                                                                                                    be
                                                                                                    guaranteed
                                                                                                    to
                                                                                                    be
                                                                                                    100%
                                                                                                    secure,
                                                                                                    so
                                                                                                    we
                                                                                                    cannot
                                                                                                    promise
                                                                                                    or
                                                                                                    guarantee
                                                                                                    that
                                                                                                    hackers,
                                                                                                    cybercriminals,
                                                                                                    or
                                                                                                    other&nbsp;unauthorized&nbsp;third
                                                                                                    parties
                                                                                                    will
                                                                                                    not
                                                                                                    be
                                                                                                    able
                                                                                                    to
                                                                                                    defeat
                                                                                                    our
                                                                                                    security
                                                                                                    and
                                                                                                    improperly
                                                                                                    collect,
                                                                                                    access,
                                                                                                    steal,
                                                                                                    or
                                                                                                    modify
                                                                                                    your
                                                                                                    information.
                                                                                                    Although
                                                                                                    we
                                                                                                    will
                                                                                                    do
                                                                                                    our
                                                                                                    best
                                                                                                    to
                                                                                                    protect
                                                                                                    your
                                                                                                    personal
                                                                                                    information,
                                                                                                    transmission
                                                                                                    of
                                                                                                    personal
                                                                                                    information
                                                                                                    to
                                                                                                    and
                                                                                                    from
                                                                                                    our
                                                                                                    Services
                                                                                                    is
                                                                                                    at
                                                                                                    your
                                                                                                    own
                                                                                                    risk.
                                                                                                    You
                                                                                                    should
                                                                                                    only
                                                                                                    access
                                                                                                    the
                                                                                                    Services
                                                                                                    within
                                                                                                    a
                                                                                                    secure
                                                                                                    environment.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="privacyrights">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            8.
                                                                                                            WHAT
                                                                                                            ARE
                                                                                                            YOUR
                                                                                                            PRIVACY
                                                                                                            RIGHTS?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <em>
                                                                                                            In
                                                                                                            Short:
                                                                                                        </em>
                                                                                                    </strong>
                                                                                                    <em>
                                                                                                        &nbsp;You
                                                                                                        may
                                                                                                        review,
                                                                                                        change,
                                                                                                        or
                                                                                                        terminate
                                                                                                        your
                                                                                                        account
                                                                                                        at
                                                                                                        any
                                                                                                        time.
                                                                                                    </em>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="withdrawconsent">
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <u>
                                                                                                            Withdrawing
                                                                                                            your
                                                                                                            consent:
                                                                                                        </u>
                                                                                                    </strong>
                                                                                                    &nbsp;If
                                                                                                    we
                                                                                                    are
                                                                                                    relying
                                                                                                    on
                                                                                                    your
                                                                                                    consent
                                                                                                    to
                                                                                                    process
                                                                                                    your
                                                                                                    personal
                                                                                                    information,&nbsp;which
                                                                                                    may
                                                                                                    be
                                                                                                    express
                                                                                                    and/or
                                                                                                    implied
                                                                                                    consent
                                                                                                    depending
                                                                                                    on
                                                                                                    the
                                                                                                    applicable
                                                                                                    law,&nbsp;you
                                                                                                    have
                                                                                                    the
                                                                                                    right
                                                                                                    to
                                                                                                    withdraw
                                                                                                    your
                                                                                                    consent
                                                                                                    at
                                                                                                    any
                                                                                                    time.
                                                                                                    You
                                                                                                    can
                                                                                                    withdraw
                                                                                                    your
                                                                                                    consent
                                                                                                    at
                                                                                                    any
                                                                                                    time
                                                                                                    by
                                                                                                    contacting
                                                                                                    us
                                                                                                    by
                                                                                                    using
                                                                                                    the
                                                                                                    contact
                                                                                                    details
                                                                                                    provided
                                                                                                    in
                                                                                                    the
                                                                                                    section&nbsp;"
                                                                                                </span>
                                                                                                <a
                                                                                                    href="#contact"
                                                                                                    data-custom-class="link"
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        HOW
                                                                                                        CAN
                                                                                                        YOU
                                                                                                        CONTACT
                                                                                                        US
                                                                                                        ABOUT
                                                                                                        THIS
                                                                                                        NOTICE?
                                                                                                    </span>
                                                                                                </a>
                                                                                                <span data-custom-class="body_text">
                                                                                                    "&nbsp;below.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    However,
                                                                                                    please
                                                                                                    note
                                                                                                    that
                                                                                                    this
                                                                                                    will
                                                                                                    not
                                                                                                    affect
                                                                                                    the
                                                                                                    lawfulness
                                                                                                    of
                                                                                                    the
                                                                                                    processing
                                                                                                    before
                                                                                                    its
                                                                                                    withdrawal
                                                                                                    nor,&nbsp;when
                                                                                                    applicable
                                                                                                    law
                                                                                                    allows,&nbsp;will
                                                                                                    it
                                                                                                    affect
                                                                                                    the
                                                                                                    processing
                                                                                                    of
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    conducted
                                                                                                    in
                                                                                                    reliance
                                                                                                    on
                                                                                                    lawful
                                                                                                    processing
                                                                                                    grounds
                                                                                                    other
                                                                                                    than
                                                                                                    consent.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <strong>
                                                                                                        <u>
                                                                                                            Opting
                                                                                                            out
                                                                                                            of
                                                                                                            marketing
                                                                                                            and
                                                                                                            promotional
                                                                                                            communications:
                                                                                                        </u>
                                                                                                    </strong>
                                                                                                    <strong>
                                                                                                        <u>
                                                                                                            &nbsp;
                                                                                                        </u>
                                                                                                    </strong>
                                                                                                    You
                                                                                                    can
                                                                                                    unsubscribe
                                                                                                    from
                                                                                                    our
                                                                                                    marketing
                                                                                                    and
                                                                                                    promotional
                                                                                                    communications
                                                                                                    at
                                                                                                    any
                                                                                                    time
                                                                                                    by&nbsp;clicking
                                                                                                    on
                                                                                                    the
                                                                                                    unsubscribe
                                                                                                    link
                                                                                                    in
                                                                                                    the
                                                                                                    emails
                                                                                                    that
                                                                                                    we
                                                                                                    send,&nbsp;or
                                                                                                    by
                                                                                                    contacting
                                                                                                    us
                                                                                                    using
                                                                                                    the
                                                                                                    details
                                                                                                    provided
                                                                                                    in
                                                                                                    the
                                                                                                    section&nbsp;"
                                                                                                </span>
                                                                                                <a
                                                                                                    href="#contact"
                                                                                                    data-custom-class="link"
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        HOW
                                                                                                        CAN
                                                                                                        YOU
                                                                                                        CONTACT
                                                                                                        US
                                                                                                        ABOUT
                                                                                                        THIS
                                                                                                        NOTICE?
                                                                                                    </span>
                                                                                                </a>
                                                                                                <span data-custom-class="body_text">
                                                                                                    "&nbsp;below.
                                                                                                    You
                                                                                                    will
                                                                                                    then
                                                                                                    be
                                                                                                    removed
                                                                                                    from
                                                                                                    the
                                                                                                    marketing
                                                                                                    lists.
                                                                                                    However,
                                                                                                    we
                                                                                                    may
                                                                                                    still
                                                                                                    communicate
                                                                                                    with
                                                                                                    you
                                                                                                    &mdash;
                                                                                                    for
                                                                                                    example,
                                                                                                    to
                                                                                                    send
                                                                                                    you
                                                                                                    service-related
                                                                                                    messages
                                                                                                    that
                                                                                                    are
                                                                                                    necessary
                                                                                                    for
                                                                                                    the
                                                                                                    administration
                                                                                                    and
                                                                                                    use
                                                                                                    of
                                                                                                    your
                                                                                                    account,
                                                                                                    to
                                                                                                    respond
                                                                                                    to
                                                                                                    service
                                                                                                    requests,
                                                                                                    or
                                                                                                    for
                                                                                                    other
                                                                                                    non-marketing
                                                                                                    purposes.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="heading_2">
                                                                                                    <strong>
                                                                                                        Account
                                                                                                        Information
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    If
                                                                                                    you
                                                                                                    would
                                                                                                    at
                                                                                                    any
                                                                                                    time
                                                                                                    like
                                                                                                    to
                                                                                                    review
                                                                                                    or
                                                                                                    change
                                                                                                    the
                                                                                                    information
                                                                                                    in
                                                                                                    your
                                                                                                    account
                                                                                                    or
                                                                                                    terminate
                                                                                                    your
                                                                                                    account,
                                                                                                    you
                                                                                                    can:
                                                                                                </span>
                                                                                            </div>
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        Log
                                                                                                        in
                                                                                                        to
                                                                                                        your
                                                                                                        account
                                                                                                        settings
                                                                                                        and
                                                                                                        update
                                                                                                        your
                                                                                                        user
                                                                                                        account.
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        Contact
                                                                                                        us
                                                                                                        using
                                                                                                        the
                                                                                                        contact
                                                                                                        information
                                                                                                        provided.
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    Upon
                                                                                                    your
                                                                                                    request
                                                                                                    to
                                                                                                    terminate
                                                                                                    your
                                                                                                    account,
                                                                                                    we
                                                                                                    will
                                                                                                    deactivate
                                                                                                    or
                                                                                                    delete
                                                                                                    your
                                                                                                    account
                                                                                                    and
                                                                                                    information
                                                                                                    from
                                                                                                    our
                                                                                                    active
                                                                                                    databases.
                                                                                                    However,
                                                                                                    we
                                                                                                    may
                                                                                                    retain
                                                                                                    some
                                                                                                    information
                                                                                                    in
                                                                                                    our
                                                                                                    files
                                                                                                    to
                                                                                                    prevent
                                                                                                    fraud,
                                                                                                    troubleshoot
                                                                                                    problems,
                                                                                                    assist
                                                                                                    with
                                                                                                    any
                                                                                                    investigations,
                                                                                                    enforce
                                                                                                    our
                                                                                                    legal
                                                                                                    terms
                                                                                                    and/or
                                                                                                    comply
                                                                                                    with
                                                                                                    applicable
                                                                                                    legal
                                                                                                    requirements.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="DNT">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            9.
                                                                                                            CONTROLS
                                                                                                            FOR
                                                                                                            DO-NOT-TRACK
                                                                                                            FEATURES
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    Most
                                                                                                    web
                                                                                                    browsers
                                                                                                    and
                                                                                                    some
                                                                                                    mobile
                                                                                                    operating
                                                                                                    systems
                                                                                                    and
                                                                                                    mobile
                                                                                                    applications
                                                                                                    include
                                                                                                    a
                                                                                                    Do-Not-Track
                                                                                                    ("DNT")
                                                                                                    feature
                                                                                                    or
                                                                                                    setting
                                                                                                    you
                                                                                                    can
                                                                                                    activate
                                                                                                    to
                                                                                                    signal
                                                                                                    your
                                                                                                    privacy
                                                                                                    preference
                                                                                                    not
                                                                                                    to
                                                                                                    have
                                                                                                    data
                                                                                                    about
                                                                                                    your
                                                                                                    online
                                                                                                    browsing
                                                                                                    activities
                                                                                                    monitored
                                                                                                    and
                                                                                                    collected.
                                                                                                    At
                                                                                                    this
                                                                                                    stage
                                                                                                    no
                                                                                                    uniform
                                                                                                    technology
                                                                                                    standard
                                                                                                    for&nbsp;recognizing&nbsp;and
                                                                                                    implementing
                                                                                                    DNT
                                                                                                    signals
                                                                                                    has
                                                                                                    been&nbsp;finalized.
                                                                                                    As
                                                                                                    such,
                                                                                                    we
                                                                                                    do
                                                                                                    not
                                                                                                    currently
                                                                                                    respond
                                                                                                    to
                                                                                                    DNT
                                                                                                    browser
                                                                                                    signals
                                                                                                    or
                                                                                                    any
                                                                                                    other
                                                                                                    mechanism
                                                                                                    that
                                                                                                    automatically
                                                                                                    communicates
                                                                                                    your
                                                                                                    choice
                                                                                                    not
                                                                                                    to
                                                                                                    be
                                                                                                    tracked
                                                                                                    online.
                                                                                                    If
                                                                                                    a
                                                                                                    standard
                                                                                                    for
                                                                                                    online
                                                                                                    tracking
                                                                                                    is
                                                                                                    adopted
                                                                                                    that
                                                                                                    we
                                                                                                    must
                                                                                                    follow
                                                                                                    in
                                                                                                    the
                                                                                                    future,
                                                                                                    we
                                                                                                    will
                                                                                                    inform
                                                                                                    you
                                                                                                    about
                                                                                                    that
                                                                                                    practice
                                                                                                    in
                                                                                                    a
                                                                                                    revised
                                                                                                    version
                                                                                                    of
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="policyupdates">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            10.
                                                                                                            DO
                                                                                                            WE
                                                                                                            MAKE
                                                                                                            UPDATES
                                                                                                            TO
                                                                                                            THIS
                                                                                                            NOTICE?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <em>
                                                                                                        <strong>
                                                                                                            In
                                                                                                            Short:&nbsp;
                                                                                                        </strong>
                                                                                                        Yes,
                                                                                                        we
                                                                                                        will
                                                                                                        update
                                                                                                        this
                                                                                                        notice
                                                                                                        as
                                                                                                        necessary
                                                                                                        to
                                                                                                        stay
                                                                                                        compliant
                                                                                                        with
                                                                                                        relevant
                                                                                                        laws.
                                                                                                    </em>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    We
                                                                                                    may
                                                                                                    update
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    from
                                                                                                    time
                                                                                                    to
                                                                                                    time.
                                                                                                    The
                                                                                                    updated
                                                                                                    version
                                                                                                    will
                                                                                                    be
                                                                                                    indicated
                                                                                                    by
                                                                                                    an
                                                                                                    updated&nbsp;"Revised"&nbsp;date
                                                                                                    and
                                                                                                    the
                                                                                                    updated
                                                                                                    version
                                                                                                    will
                                                                                                    be
                                                                                                    effective
                                                                                                    as
                                                                                                    soon
                                                                                                    as
                                                                                                    it
                                                                                                    is
                                                                                                    accessible.
                                                                                                    If
                                                                                                    we
                                                                                                    make
                                                                                                    material
                                                                                                    changes
                                                                                                    to
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice,
                                                                                                    we
                                                                                                    may
                                                                                                    notify
                                                                                                    you
                                                                                                    either
                                                                                                    by
                                                                                                    prominently
                                                                                                    posting
                                                                                                    a
                                                                                                    notice
                                                                                                    of
                                                                                                    such
                                                                                                    changes
                                                                                                    or
                                                                                                    by
                                                                                                    directly
                                                                                                    sending
                                                                                                    you
                                                                                                    a
                                                                                                    notification.
                                                                                                    We
                                                                                                    encourage
                                                                                                    you
                                                                                                    to
                                                                                                    review
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    frequently
                                                                                                    to
                                                                                                    be
                                                                                                    informed
                                                                                                    of
                                                                                                    how
                                                                                                    we
                                                                                                    are
                                                                                                    protecting
                                                                                                    your
                                                                                                    information.
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="contact">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            11.
                                                                                                            HOW
                                                                                                            CAN
                                                                                                            YOU
                                                                                                            CONTACT
                                                                                                            US
                                                                                                            ABOUT
                                                                                                            THIS
                                                                                                            NOTICE?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    If
                                                                                                    you
                                                                                                    have
                                                                                                    questions
                                                                                                    or
                                                                                                    comments
                                                                                                    about
                                                                                                    this
                                                                                                    notice,
                                                                                                    you
                                                                                                    may&nbsp;email
                                                                                                    us
                                                                                                    at&nbsp;support@faym.co
                                                                                                    or&nbsp;contact
                                                                                                    us
                                                                                                    by
                                                                                                    post
                                                                                                    at:
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    POSTFAYM
                                                                                                    TECHNOLOGIES
                                                                                                    PRIVATE
                                                                                                    LIMITED
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    __________
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    __________
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="request">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            12.
                                                                                                            HOW
                                                                                                            CAN
                                                                                                            YOU
                                                                                                            REVIEW,
                                                                                                            UPDATE,
                                                                                                            OR
                                                                                                            DELETE
                                                                                                            THE
                                                                                                            DATA
                                                                                                            WE
                                                                                                            COLLECT
                                                                                                            FROM
                                                                                                            YOU?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    Based
                                                                                                    on
                                                                                                    the
                                                                                                    applicable
                                                                                                    laws
                                                                                                    of
                                                                                                    your
                                                                                                    country,
                                                                                                    you
                                                                                                    may
                                                                                                    have
                                                                                                    the
                                                                                                    right
                                                                                                    to
                                                                                                    request
                                                                                                    access
                                                                                                    to
                                                                                                    the
                                                                                                    personal
                                                                                                    information
                                                                                                    we
                                                                                                    collect
                                                                                                    from
                                                                                                    you,
                                                                                                    change
                                                                                                    that
                                                                                                    information,
                                                                                                    or
                                                                                                    delete
                                                                                                    it.&nbsp;To
                                                                                                    request
                                                                                                    to
                                                                                                    review,
                                                                                                    update,
                                                                                                    or
                                                                                                    delete
                                                                                                    your
                                                                                                    personal
                                                                                                    information,
                                                                                                    please&nbsp;fill
                                                                                                    out
                                                                                                    and
                                                                                                    submit
                                                                                                    a&nbsp;
                                                                                                </span>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <a
                                                                                                        href="https://app.termly.io/notify/a310e9f2-54ee-4fca-b919-92d3103cedd2"
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        data-custom-class="link"
                                                                                                    >
                                                                                                        data
                                                                                                        subject
                                                                                                        access
                                                                                                        request
                                                                                                    </a>
                                                                                                </span>
                                                                                                <span data-custom-class="body_text">
                                                                                                    .
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div id="return">
                                                                                                <span id="control">
                                                                                                    <strong>
                                                                                                        <span data-custom-class="heading_1">
                                                                                                            13.
                                                                                                            REFUND
                                                                                                            AND
                                                                                                            RETURN
                                                                                                            POLICY?
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div>
                                                                                                <span data-custom-class="body_text">
                                                                                                    General
                                                                                                    Policy
                                                                                                    We
                                                                                                    appreciate
                                                                                                    you
                                                                                                    choosing
                                                                                                    FAYM!
                                                                                                    Our
                                                                                                    goal
                                                                                                    is
                                                                                                    to
                                                                                                    deliver
                                                                                                    outstanding
                                                                                                    support
                                                                                                    to
                                                                                                    both
                                                                                                    creators
                                                                                                    and
                                                                                                    brands.
                                                                                                    Please
                                                                                                    note
                                                                                                    that
                                                                                                    due
                                                                                                    to
                                                                                                    the
                                                                                                    nature
                                                                                                    of
                                                                                                    affiliate
                                                                                                    marketing,
                                                                                                    the
                                                                                                    commission
                                                                                                    process
                                                                                                    can
                                                                                                    take
                                                                                                    between
                                                                                                    45
                                                                                                    and
                                                                                                    60
                                                                                                    days.
                                                                                                    This
                                                                                                    timeframe
                                                                                                    allows
                                                                                                    for
                                                                                                    product
                                                                                                    delivery,
                                                                                                    order
                                                                                                    verification,
                                                                                                    and
                                                                                                    data
                                                                                                    reconciliation
                                                                                                    with
                                                                                                    our
                                                                                                    brand
                                                                                                    partners.
                                                                                                    Once
                                                                                                    the
                                                                                                    final
                                                                                                    commission
                                                                                                    amount
                                                                                                    is
                                                                                                    confirmed,
                                                                                                    it
                                                                                                    will
                                                                                                    be
                                                                                                    approved
                                                                                                    in
                                                                                                    your
                                                                                                    FAYM
                                                                                                    wallet.
                                                                                                    a&nbsp;
                                                                                                </span>
                                                                                            </div>
                                                                                            <br />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
