import React, { useEffect, useState } from "react";
import styles from "./index.module.css";

function QrCode() {
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;
            const scrollHeight =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight;
            const scrolledPercentage = (scrollPosition / scrollHeight) * 100;

            if (scrolledPercentage >= 82.5) {
                setFadeOut(true);
            } else {
                setFadeOut(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className='qr'>
            <div
                className={`${styles.container} ${
                    fadeOut ? styles.fadeOut : ""
                }`}
            >
                <div className={styles.innerbox}>
                    <img
                        className={styles.image}
                        src='/qr-code.png'
                        alt='qrcode'
                    />
                    <div className={styles.text}>Download Faym</div>
                </div>
            </div>
        </div>
    );
}

export default QrCode;
