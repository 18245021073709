import React from "react";
import NavBar from "../components/CommonComponents/Navbar";
import MainSection from "../components/caseStudies/MainSection";
import Footer from "../components/CommonComponents/Footer";

const AllCaseStudiesPage = () => {
    return (
        <div>
            <NavBar />
            <MainSection />
            <Footer />
        </div>
    );
};

export default AllCaseStudiesPage;
