import {
    Routes,
    Route,
    useNavigationType,
    useLocation,
    useNavigate,
    Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import TermsAndConditions from "./pages/TermsAndConditionPage";
import CreatorsPage from "./pages/CreatorPage";
import BrandsPage from "./pages/BrandsPage";
import BrandCaseStudiesPage from "./pages/BrandCaseStudiesPage";
import AllCaseStudiesPage from "./pages/AllCaseStudiesPage";
import FaqsPage from "./pages/FaqsPage";

function App() {
    const action = useNavigationType();
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();

    function onLoad(location: string, referral: string) {
        if (referral)
            ReactGA.event({
                category: "Visited Website",
                action: "Referred User: " + referral.split("=")[1],
                label: location + " | " + referral.split("=")[1],
            });
        else
            ReactGA.event({
                category: "Visited Website",
                action: "Not referred User",
                label: location,
            });
    }

    useEffect(() => {
        if (action !== "POP") {
            window.scrollTo(0, 0);
        }
    }, [action, pathname]);

    useEffect(() => {
        let title = "";
        let metaDescription = "";

        switch (pathname) {
            case "/":
                title = "";
                metaDescription = "";
                break;
        }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag: HTMLMetaElement | null =
                document.querySelector('head > meta[name="description"]');
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
    }, [pathname]);

    useEffect(() => {
        const referral = window.location.search;
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
        });
        onLoad(window.location.pathname, referral);
    }, []);

    return (
        <Routes>
            <Route path='/' element={<BrandsPage />} />
            <Route path='/faqs' element={<FaqsPage />} />
            <Route path='/creators' element={<CreatorsPage />} />
            <Route path='/casestudies' element={<AllCaseStudiesPage />} />
            <Route
                path='/casestudies/:brand/:id'
                element={<BrandCaseStudiesPage />}
            />
            <Route path='/tnc' element={<TermsAndConditions />} />
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
}
export default App;
